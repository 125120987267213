import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  HoverEffect,
  PlainLink,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
/* eslint-disable import/no-unresolved */
import Illustration30DaysFree from "src/assets/png/illustration-30daysfree@2x.png";
import { DISCOUNT_EXTENSION_LENGTH } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";

interface ExtendDiscountBoxProps {
  collectionsEnabled?: boolean;
}

const ExtendDiscountBox: React.FC<ExtendDiscountBoxProps> = ({
  collectionsEnabled,
}) => (
  <Box
    bg={ColorPreset.BackgroundLight_00}
    gutterH={1.5}
    gutterV={1.5}
    layout="flex"
    flexDirection={["column", "row"]}
    alignItems={AlignItems.Center}
  >
    <img
      src={Illustration30DaysFree}
      alt="Extend discount"
      css={{ width: "120px", height: "120px" }}
    />
    <Box spaceBefore={[0, 1]} spaceAbove={[1, 0]}>
      <Text preset={TypePreset.Body_03} weight={FontWeight.SemiBold}>
        <Trans id="extend-discount.dialog.body-title">
          Get 30 extra days without fees!
        </Trans>
      </Text>
      <Space v={1} />
      <Text preset={TypePreset.Body_01}>
        {collectionsEnabled ? (
          <Trans id="extend-discount.dialog.body-desc.cpv-variant">
            Collect your first payment within your first{" "}
            {DISCOUNT_EXTENSION_LENGTH} days and we&apos;ll extend your no-fee
            period by 30 days! Please note: the discount will be applied the
            following day.
          </Trans>
        ) : (
          <Trans id="extend-discount.dialog.body-desc">
            Get paid out to your bank account in your first{" "}
            {DISCOUNT_EXTENSION_LENGTH} days and we&apos;ll extend your no-fee
            period by 30 days! Please note: the discount will be applied the
            following day.
          </Trans>
        )}{" "}
        <PlainLink
          href={
            collectionsEnabled
              ? "https://gocardless.com/g/no-fee-terms-lro/"
              : "https://gocardless.com/g/no-fee-terms-fpj/"
          }
          target="_blank"
          rel="noreferrer"
          textDecoration="underline"
          effect={HoverEffect.TextDecoration}
        >
          <Trans id="extend-discount.dialog.view-terms">View terms</Trans>
        </PlainLink>
      </Text>
    </Box>
  </Box>
);

export default ExtendDiscountBox;
