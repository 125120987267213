import { useAuthenticationFactorList } from "@gocardless/api/dashboard/authentication-factor";
import { useMandateList } from "@gocardless/api/dashboard/mandate";
import { usePaymentList } from "@gocardless/api/dashboard/payment";
import {
  AuthenticationFactorsStatus,
  PaymentStatusEnum,
  ReferenceType,
} from "@gocardless/api/dashboard/types";
import { useLocalStorage } from "src/common/hooks/useLocalStorage/useLocalStorage";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";
import { usePrimaryCreditor } from "src/queries/creditor";

interface UseKccChecklistDialog {
  stepsCompleted: {
    brandExperience: boolean;
    collectFirstPayment: boolean;
    bookSession: boolean;
    collectThreePayments: boolean;
    acceptPayments: boolean;
    moveCustomers: boolean;
    enableTwoFactorAuth: boolean;
  };
  markStepCompleted: (
    step: "bookSession" | "acceptPayments" | "moveCustomers"
  ) => void;
  allStepsCompleted: boolean;
  hasMandates: boolean;
}

export function useKccChecklistDialog(): UseKccChecklistDialog {
  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_KEEP_COLLECTING_CONFIDENTLY,
  });

  const creditor = usePrimaryCreditor();

  const { data: payments } = usePaymentList(
    {
      include: ReferenceType.Customer,
      status: PaymentStatusEnum.Confirmed,
    },
    isVariationOn
  );
  const { data: authenticationFactors } = useAuthenticationFactorList(
    {
      is_default: true,
      status: AuthenticationFactorsStatus.Verified,
    },
    isVariationOn
  );
  const { data: mandates } = useMandateList({ limit: 1 }, isVariationOn);

  const [bookSession, setBookSession] = useLocalStorage(
    "gc.kcc-checklist-dialog.steps-completed.book-session",
    "false"
  );
  const [acceptPayments, setAcceptPayments] = useLocalStorage(
    "gc.kcc-checklist-dialog.steps-completed.accept-payments",
    "false"
  );
  const [moveCustomers, setMoveCustomers] = useLocalStorage(
    "gc.kcc-checklist-dialog.steps-completed.move-customers",
    "false"
  );

  function markStepCompleted(
    step: "bookSession" | "acceptPayments" | "moveCustomers"
  ) {
    switch (step) {
      case "bookSession":
        setBookSession("true");
        return;
      case "acceptPayments":
        setAcceptPayments("true");
        return;
      case "moveCustomers":
        setMoveCustomers("true");
        return;
    }
  }

  const hasCollectedFirstPayment = payments?.payments
    ? payments.payments.length > 0
    : false;
  const hasCollectedThreePayments = payments?.payments
    ? payments.payments.length > 2
    : false;
  const hasEnabledTwoFactorAuth = authenticationFactors?.authentication_factors
    ? authenticationFactors?.authentication_factors.length > 0
    : false;
  const hasMandates = mandates?.mandates ? mandates.mandates.length > 0 : false;

  const stepsCompleted = {
    brandExperience: Boolean(creditor?.logo_url),
    collectFirstPayment: hasCollectedFirstPayment,
    bookSession: bookSession === "true",
    collectThreePayments: hasCollectedThreePayments,
    acceptPayments: acceptPayments === "true",
    moveCustomers: moveCustomers === "true",
    enableTwoFactorAuth: hasEnabledTwoFactorAuth,
  };

  // true when all stepsCompleted are true
  const allStepsCompleted = Object.values(stepsCompleted).every((x) => x);

  return {
    stepsCompleted,
    markStepCompleted,
    allStepsCompleted,
    hasMandates,
  };
}
